import NavBar from "../model/NavBar";
import Post from "../controller/Post";

export default function Posts(){
    return(
        <>
            <NavBar/>
            <Post/>
        </>
    )
}