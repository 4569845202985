export default function AboutSection() {
    
    return(<>
        <div className="section about-description">
        <img src="https://discord.bimash.com.np/assets/img/path4.png" className="path path4 px-5"/>
        <div className="container">
            <div className="row mb-5">
                <div className="col-md-8 ml-auto mr-auto text-center">
                    <h2 className="title">Convert your awesome Imaginatin</h2>
                    <h4 className="description text-justify">Bring your imagination to life with the power of artificial intelligence! Let A.I technology translate your visions and ideas into beautiful and unique digital artworks. Imagine a scene, a character, or a landscape, and let A.I take care of the rest. The resulting artwork will not only be a visual representation of your creativity, but also an opportunity to share your imagination with the world. By sharing your artworks with others, you will inspire and be inspired, creating a community of creative and imaginative individuals. So, why wait? Unleash your imagination today and let A.I draw your vision!</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 ml-auto mr-auto">
                    <img className="d-block w-100" src="https://discord.bimash.com.np/assets/img/midaiabout1.png" alt="projext"/>
                </div>
            </div>
        </div>
    </div>
    
    </>)
}