
import AboutHeader from '../model/AboutHeader';
import AboutSection from '../model/AboutSection';
import Developer from '../model/Developer';
import NavBar from '../model/NavBar';
export default function About() {
  return (
    <>
        <NavBar/>
        <AboutHeader/>
        <AboutSection/>
        <Developer />
    </>
  );
}

