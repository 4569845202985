import { NavLink } from "react-router-dom"
export default  function HeroHeader(){
    return(<>
    <div className="wrapper">
        <div className="page-header">
            <div className="squares square1"></div>
            <div className="squares square2"></div>
            <div className="squares square3"></div>
            <div className="squares square4"></div>
            <div className="squares square5"></div>
            <div className="squares square6"></div>
            <div className="squares square7"></div>
            <div className="container">
                <div className="content-center brand">
                    <h1 className="h1-seo  text-primary">Meanwhile  A.I</h1>
                    <h3>Share your imagination</h3>
                    <NavLink target="blank" to='https://discord.gg/2GAZMj5CfJ' className="btn btn-simple  btn-lg btn-primary rounded-pill">Get Started <i className="fa-duotone fa-arrow-right"></i></NavLink>
                </div>
            </div>
        </div>
    </div>
    </>)
}
{/* <span classNameName=""><i className="fa-brands fa-discord"></i> </span>
                    <span classNameName=""><i className="fa-brands fa-facebook-f"></i> </span> */}