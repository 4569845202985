import { NavLink } from "react-router-dom";

export default function Contents(){
    return(<>
    <div className="section section-content" data-background-color="black">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="image-container">
                        <img className="img img-raised rounded img-comments rellax" data-rellax-speed="1" src="https://discord.bimash.com.np/assets/img/aiimage2.png"/>
                        <img className="img img-raised rounded img-blog rellax" data-rellax-speed="4" src="https://discord.bimash.com.np/assets/img/aiimg1.png"/>
                    </div>
                </div>
                <div className="col-lg-6 ml-auto mr-auto">
                    <div className="section-description">
                        <h1 className="title">Explore AI-Generated Art</h1>
                        <h5 className="category text-danger "><strong>See what other are creating</strong></h5>
                        <p className="description my-3">Discover a world of imagination and creativity with AI-generated art. View a wide collection of unique and stunning images created by artists from all over the world using cutting-edge artificial intelligence technology. Immerse yourself in a kaleidoscope of colors, shapes, and patterns, and be inspired to create your own AI-powered masterpieces. 
                        </p>
                        <NavLink target="blank" to='https://discord.gg/2GAZMj5CfJ' className="btn btn-simple btn-primary rounded-pill">Start your journey now <i className="fa-duotone fa-arrow-right"></i></NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>)
}