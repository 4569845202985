import { NavLink } from "react-router-dom"
export default function Developer() {
    return(
        <>
        <div className="section">
            <div className="team-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 ml-auto mr-auto text-center">
                            <h2 className="title">Our Team</h2>
                            <h4 className="description">Nobody, It's just me</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div id="carouselExampleControls" className="carousel slide carousel-team">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h1 className="title">Bimash Maharjan</h1>
                                            </div>
                                            <div className="col-md-4">
                                                <img className="d-block" src="https://bimash.com.np/assets/img/profile-img.jpg" alt="First slide"/>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="wrapper">
                                                    <div className="description">
                                                    <q>
                                                       If you only do what you can do, you'll never be better than what you are
                                                    </q>
                                                    </div>
                                                    <div className="footer">
                                                        <NavLink target="_blank" to="https://github.com/bmasmhj" className="btn btn-icon btn-github btn-round"><i className="fab fa-github"></i></NavLink>
                                                        <NavLink target="_blank" to="https://www.facebook.com/bimash.maharjan" className="btn btn-icon btn-facebook btn-round"><i className="fab fa-facebook-square"></i></NavLink>
                                                        <NavLink target="_blank" to="https://instagram.com/bimash.maharjan" className="btn btn-icon btn-instagram btn-round"><i className="fab fa-instagram"></i></NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}