import Crayion from '../controller/Crayion';
import Post from '../controller/Post';
import NavBar from '../model/NavBar';

export default function Generate(){
    return(<>
        <NavBar />
        <div className="wrapper">
            <div className="page-header">
                <div className="squares square1"></div>
                <div className="squares square2"></div>
                <div className="squares square3"></div>
                <div className="squares square4"></div>
                <div className="squares square5"></div>
                <div className="squares square6"></div>
                <div className="squares square7"></div>
                <div className="container">
                    <div className="content-center brand">
                        <Crayion/>
                    </div>
                </div>
            </div>
        </div>
        <Post/>
    </>)
}
