import { NavLink } from "react-router-dom"
import { ReactComponent as Svg } from "../assets/img/about.svg";

export default  function AboutHeader(){
    return(<>
    <div className="wrapper">
        <div className="page-header">
            <div className="squares square1"></div>
            <div className="squares square2"></div>
            <div className="squares square3"></div>
            <div className="squares square4"></div>
            <div className="squares square5"></div>
            <div className="squares square6"></div>
            <div className="squares square7"></div>
            <div className="container d-flex flex-column align-items-center ">
                <h2 className="mt-5">WORK FLOW</h2>
                <Svg className="mb-5 w-100"/>
                <NavLink target="blank" to='https://discord.gg/2GAZMj5CfJ' className="btn btn-simple  btn-lg btn-primary rounded-pill">Get Started <i className="fa-duotone fa-arrow-right"></i></NavLink>
            </div>
        </div>
    </div>
    </>)
}
{/* <span classNameName=""><i className="fa-brands fa-discord"></i> </span>
                    <span classNameName=""><i className="fa-brands fa-facebook-f"></i> </span> */}