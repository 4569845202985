
import Contents from '../model/Contents';
import GenerateImage from '../model/GenerateImage'
import HeroHeader from '../model/HeroHeader';
import NavBar from '../model/NavBar';
export default function HomePage() {
  return (
    <>
        <NavBar/>
        <HeroHeader/>
        <GenerateImage/>
        <Contents />
    </>
  );
}

