import { NavLink } from "react-router-dom";
import { useState , useEffect } from "react";
import Instance from "../axios";

export default function Post() {
    const [aiposts , setpost] = useState([]);
    const epochs = [
                    ['year', 31536000],
                    ['month', 2592000],
                    ['day' , 86400],
                    ['hour', 3600],
                    ['minute', 60],
                    ['second',1]];
        const getDuration = (timeAgoInSeconds) =>{
            for(let [name , seconds] of epochs){
                const interval = Math.floor(timeAgoInSeconds / seconds);
                if(interval >=1 ){
                    return{ interval : interval,epoch : name} ;
                }
            }   
        };
        const timeAgo = (date) =>{
            const timeAgoInSeconds = Math.floor((new Date()- new Date(date))/1000);
            const{  interval , epoch} = getDuration(timeAgoInSeconds);
            const suffix = interval === 1 ?'' : 's';
            return `${interval} ${epoch} ${suffix} ago`;
        };
        const prompt =  (message) =>{
            try{
                return message.match(/\[(.*?)\]/)[0].replace("[","").replace("]","")
            }catch{
                return message
            }
        }

        const name =  (message) =>{
            try{
                return message.replace("Prompt by ","").split(" ")[0];
                
            }catch{
                return "Juman"
            }
        }
    const pupularcourses = async () => {
        try {
            const res_course = await Instance.get(`/`);
            // console.log(res_course.data.feed.data)
            setpost(res_course.data.feed.data);
        } catch (error) {
            console.log("error", error);
        }
    };
    useEffect(() => {
        pupularcourses();
    },[]);
if(aiposts!== undefined){
    return (
        <>
            <section className="m-0 py-5  position-relative">
                <div className="container">
                    <div className="row">
                        <section className="py-5">
                            <div className="container">
                                <div className="row mt-5" id="popcourses">
                                    {
                                    aiposts.map((resdata)=>{
                                        if(resdata.full_picture){
                                        return(
                                            <>
                                                <div className="col-md-4" key={resdata.id}>
                                                    <div className="card card-blog card-plain">
                                                        <div className="card-header my-2 d-flex justify-content-between">
                                                            <h6 className="title m-0 fit-content">{name(resdata.message)}</h6>  
                                                            <small className="float-end">{timeAgo(resdata.created_time)}</small>
                                                        </div>
                                                        <div className="card-image">
                                                            <img alt={name(resdata.message)} className="img rounded img-raised" src={resdata.full_picture}/>
                                                        </div>
                                                        <div className="card-footer text-left">
                                                            <div className="author">
                                                                <p className="promt_title">{prompt(resdata.message)}</p>
                                                                <NavLink to={`https://www.facebook.com/${resdata.id}`} className="btn btn-info btn-round btn-simple"><i className="tim-icons icon-minimal-right"></i>View on Facebook</NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        }

                                    })
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </>
    );
    }
}

