import { useState , useEffect } from "react";
import Instance from "../axios";

export default function Crayion() {
    
    const [aioutput, setaioutput] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nothing, setNothing] = useState(true);
    const [previewImage, setPreviewImage] = useState("");

    const changeIt = (src) => {
        setPreviewImage(src);
    };
     const aiimages = async () => {
         try {
             var prompt = document.getElementById('prompt').value;
             if (prompt.trim() !== '') {
                setNothing(false)
                setLoading(true);
                var i = 0;
                const loading = setInterval(() => {
                    i++
                    document.getElementById('loading_timer').innerHTML = i;
                }, 100);
                const res_aioutout = await Instance.get(`/Generate?prompt=${prompt}`);
                setaioutput(res_aioutout.data.images);
                 setLoading(false);
                 document.getElementById('res_text').innerHTML = prompt;
                 document.getElementById('prompt').value = '';
                 clearInterval(loading)
               
             }
             else {
                 alert('type something')
             }

        } catch (error) {
            console.log("error", error);
             setLoading(false);
         }
         
      
    };
    
   
    useEffect(() => {
       
    },[]);
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="input-group mb-2">
                        <input id='prompt' type="text" className="form-control" placeholder="Type your prompt here"/>
                        <div className="input-group-append">
                            <button  onClick={aiimages} className="input-group-text generatebutton p-1"><span className="mx-1">Generate</span>  &#32;&#32;<i className="mx-1 fa-light fa-paper-plane"></i></button>
                        </div>
                    </div>
                    <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                        <h3 className="m-0">Output : <span id='res_text'></span></h3>
                        <div className="card mt-0 imageoutput">
                        {loading ? 
                            <div className="d-flex align-items-center justify-content-center w-100 h-max flex-column">
                                <h4>L O A D I N G</h4>
                                <div id="loading_timer">
                                </div>
                            </div>
                        : 
                        nothing ? 
                            <div className="d-flex align-items-center justify-content-center w-100 h-max flex-column">
                                    <h4>Type anything in prompt and click generate</h4>
                                    <h5>Tips : You can click on Image to Zoom</h5>
                            </div>
                        :
                        <div className="row p-0" id='imageoutput'>
                            {
                                aioutput.map((aidata) => {
                                        return(
                                            <>
                                                <div className="col-4 p-1 m-0">
                                                    <img   onClick={() => changeIt(`data:image/webp;base64,${aidata}`)} className="generatedimg" src={`data:image/webp;base64,${aidata}`} alt="" />
                                                </div>
                                            </>
                                        )
                                })
                            }
                        </div>
                        }
                        </div>
                    </div>
                   
                </div>    
            </div>
            <div id="preview_image">
                {previewImage !== '' ? <>
                    <span onClick={() => changeIt(``)} id="close_preview_image"><i class="fa-solid fa-xmark"></i></span>
                    <img className="preview_image" src={previewImage} /> </> : null}
            </div>
        </>
    )

    
}
function changeIt(src){
    const previewImage = document.querySelector("#preview_image");
    previewImage.innerHTML = `<img src="${src}" />`;
}