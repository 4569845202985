import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
export default function NavBar() {
    const sendmail= (e)=>{
        e.preventDefault();
        var sender = document.querySelector("#contact_email").value;
        var contact_name = document.querySelector("#contact_name").value;
        var contact_message = document.querySelector("#contact_message").value;
            if (sender === "") {
                document.querySelector("#contact_email").classList.add("is-invalid");
            }
            if (contact_message === "") {
                document.querySelector("#contact_message").classList.add("is-invalid");
            }
            if (contact_name === "") {
                document.querySelector("#contact_name").classList.add("is-invalid");
            }
            var title = `${contact_name} : ${sender}`;
            var subjects = `${sender} sent a message </br> Message from meanwhile ai: </br> ${contact_message}`;
               if (validateForm("contact_me_now") === true) {
                    axios.get('https://bimash.com.np/email/contactme.php', {
                         params: {
                            sender,
                            contact_name
                        }
                    })
                    .then(function (response) {
                         mailforwarder(title , subjects , 'contactmessage' , 'contact_me_now' )
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
               }
            }
            function mailforwarder(title, subjects, idex, formidex) {
                axios.get('https://bimash.com.np/email/mailforward.php', {
                    params: {
                        title,
                        subjects
                    }
                })
                .then(function (response) {
                    var res = response.data;
                    if (res === 'sent') {
                    document.querySelector(`#${idex}`).innerHTML = `<p class='text-white text-center p-2 bg-gradient-success'>Thanks for reaching me out ! 😇 <br> Check out your mail for my response 💕 </p>`;
                    setTimeout(() => {
                        document.querySelector(`#${idex}`).innerHTML = '';
                    }, 5000);
                    document.getElementById(formidex).reset();
                    } else {
                    document.querySelector(`#${idex}`).innerHTML = `<p class='text-white text-center p-2 bg-gradient-success'>Sorry ! Error Occuredd</p>`;
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
                }

            function validateForm(id) {
                var isValid = true;
                var formElements = document.querySelectorAll("." + id);
                for (var i = 0; i < formElements.length; i++) {
                    if (formElements[i].value === "") {
                        isValid = false;
                        break;
                    }
                }
                return isValid;
            }

            var inputTypes = ["input[type=text]", "textarea", "input[type=email]"];
            inputTypes.forEach(function(inputType) {
            document.querySelectorAll(inputType).forEach(function(element) {
                element.addEventListener("keyup", function(event) {
                event.preventDefault();
                var id = this.id;
                document.querySelector("#" + id).classList.remove("is-invalid");
                });
            });
            });

    

    useEffect(() => {
       if(document.getElementById("fixattop")){
          var header = document.getElementById("fixattop");
            var sticky = header.offsetTop;
            window.onscroll = function() {
               if (window.pageYOffset > sticky) {
                    header.classList.add("bg-dark");
                    document.getElementById("wht1").classList.remove("text-white");
                } else {
                    header.classList.remove("bg-dark");
                    document.getElementById("wht1").classList.add("text-white");
                }
            
            };
        }
    }, []);


    return (<>
        <nav className="navbar navbar-expand-lg stroke w-100 bg-transparent  position-fixed z-index-3  navbar-light" id="fixattop">
            <div className="container justify-content-between align-items-center">
                <h3 className="fit-content flex-grow-1 m-0" id="wht1">
                    Meanwhile A.I
                </h3>
                <div className="collapse navbar-collapse w-80 pt-3 pb-2 py-lg-0" id="navigation">
                    <ul id="navbar-nav-center" className="navbar-nav navbar-nav-hover mx-auto">
                        <li className="p-2 nav-item mx-2">
                            <NavLink to="/" className="nav-link d-flex justify-content-between cursor-pointer align-items-center p-0" role="button">
                                Home
                            </NavLink>
                        </li>
                        <li className="p-2 nav-item mx-2">
                            <NavLink to="/About" className="nav-link  d-flex justify-content-between cursor-pointer align-items-center p-0" role="button">
                                About
                            </NavLink>
                        </li>
                        <li className="p-2 nav-item mx-2">
                            <NavLink to="/Post" className="nav-link  d-flex justify-content-between cursor-pointer align-items-center p-0" role="button">
                                Post
                            </NavLink>
                        </li>
                        <li className="p-2 nav-item mx-2">
                            <NavLink to="/Generate" className="nav-link  d-flex justify-content-between cursor-pointer align-items-center p-0" role="button">
                                Generate
                            </NavLink>
                        </li>
                        <li className="nav-item p-1 mx-2">
                            <button data-bs-toggle="modal" data-bs-target="#contactpage" className=" btn btn-sm  bg-gradient-primary  btn-round mb-0">Contact</button>
                        </li>
                    </ul>
                     <NavLink to="https://discord.gg/2GAZMj5CfJ" className="nav-link  d-flex justify-content-between cursor-pointer align-items-center p-0" role="button">
                            <i className="fa-brands text-white fa-discord"></i>
                    </NavLink>
                    <NavLink to="https://www.facebook.com/meanwhileai/" className="nav-link mx-2 d-flex justify-content-between cursor-pointer align-items-center p-0" role="button">
                        <i className="fa-brands text-white  fa-facebook"></i>
                    </NavLink>
                </div>
            </div>
        </nav>
        <div className="modal fade" id="contactpage" tabIndex="-1" aria-labelledby="exampleModalSignup" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
                <div className="modal-content">
                <div className="modal-body p-0">
                    <div className="card bg-dark text-white card-plain">
                    <div className="card-header pb-0 text-start">
                        <h3 className="font-weight-bolder text-primary text-gradient">Contact Us</h3>
                        <p className="mb-0 text-white">Please leave valid Email to get reply.</p>
                    </div>
                    <div className="card-body pb-3">
                        <form action="#" id="contact_me_now" className="contact_me_now needs-validation">
                        <label>Full Name</label>
                        <div className="input-group mb-3">
                            <input required="" id="contact_name" type="text" className="form-control" placeholder="Full Name"/>
                        </div>
                        <label>Email</label>
                        <div className="input-group mb-3">
                            <input required="" id="contact_email" type="email" className="form-control" placeholder="Email"/>
                        </div>
                        <label>Message</label>
                        <div className="input-group mb-3">
                            <textarea required="" name="" id="contact_message" cols="30" className="form-control" rows="5"></textarea>
                        </div>
                        <div id="contactmessage">
                        </div>
                        <div className="text-center">
                            <button id="contactmeinstant" name="contactmeinstant" value="contactmeinstant" onClick={sendmail} className="btn bg-gradient-primary btn-lg btn-rounded w-100 mt-4 mb-0">Send Message</button>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </>)
}


