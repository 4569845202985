import { ReactComponent as Svg } from "../assets/img/art.svg";
import { NavLink } from "react-router-dom";
import '../assets/css/cstm.scss';

export default function GenerateImage(){
    return(
        <>
        <div className="section section-basic-components">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-7 ml-auto mr-auto">
                        <h1 className="title">Share Imagination</h1>
                        <h5 className="category text-primary"><strong>Share your imagination to world</strong></h5>
                        <p className="description my-3 text-justify">
                       Unleash your imagination with <strong> Meanwhile A.I bot </strong>- the Discord bot that takes your AI-generated images from Midjourney and showcases them on our Facebook page and website. Simply imagine and write a prompt, and let <strong>Meanwhile A.I</strong> bot handle the rest. Share your creativity with the world and join the AI art revolution today.
                        </p>
                        <NavLink target="blank" to='https://discord.gg/2GAZMj5CfJ' className="btn btn-simple  btn-primary rounded-pill"><i className="fa-brands fa-discord"></i> Join Discord </NavLink>
                        <NavLink target="blank" to='/Post' className="mx-2 btn btn-simple  btn-primary rounded-pill">View Post <i className="fa-duotone fa-arrow-right"></i></NavLink>

                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="image-container">
                            <img className="table-img" src="https://discord.bimash.com.np/assets/img/discord.png" alt=""/>
                            <img className="share-btn-img" src="https://discord.bimash.com.np/assets/img/midbot.png" alt=""/>
                            <img className="coloured-card-btn-img" src="https://discord.bimash.com.np/assets/img/prompteg.png" alt=""/>
                            <img className="coloured-card-img" src="https://discord.bimash.com.np/assets/img/chaneldes.png" alt=""/>
                            <img className="linkedin-btn-img" src="https://discord.bimash.com.np/assets/img/botname.png" alt=""/>
                            <img src="https://discord.bimash.com.np/assets/img/path3.png" className="path path3"/> 
                            <Svg/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</>
    )
}