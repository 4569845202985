import {  Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Generate from "./pages/Generate";
import HomePage from './pages/HomePage'
import Post from "./pages/Post";

function App() {
  return (
    <>
      <Routes>
            {/* 👇️ handle dynamic path */}
            <Route path="/Post" element={<Post />} />
            <Route path="/About" element={<About />} />
            <Route path="/Generate" element={<Generate />} />
            <Route path="/" element={<HomePage />} />

            {/* 👇️ only match this when no other routes match */}
            <Route
              path="*"
              element={
                    <HomePage/>
              }
            />
        </Routes>  
    </>
  );
}

export default App;
